import React from 'react';

import { Localize } from '@kokolingo/localisation';

import { HubspotInstitutionsForm } from '../../fragments';

import './index.css';

const Institutions = () => {
  return (
    <div className="bg form-page">
      <div className="form-container">
        <div>
          <h1>
            <Localize id="institutions.Title" />
          </h1>
          <HubspotInstitutionsForm />
        </div>
      </div>
    </div>
  );
};

export default Institutions;
